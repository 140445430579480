import {REDIRECT_URL} from './utils';
export type EnumMediaType = 'image' | 'video' | 'carousel';

export type TypePostData = {
	alt_text: string;
	link: string;
	media_type: EnumMediaType;
	media_url: string;
	post_created_epoch: number;
	post_guid: string;
};

export const getPostsData = (profile: string): Promise<Array<TypePostData>> => {
	if (!profile) {
		return Promise.resolve([]);
	}
	return fetch(`/${profile}/posts.json`, {cache: 'no-cache'}).then(response => {
		const contentType = response.headers.get('content-type');
		if (!contentType || !contentType.includes('application/json')) {
			return [];
		}
		return response.json();
	});
};

export type TypePageData = {
	hero_image_url: string;
	hero_text: string;
	link_button_text: string;
	link_button_url: string;
	profile_image_url: string;
	profile_name: string;
	page_id?: number;
};

export const getPageData = (profile: string): Promise<TypePageData | null | undefined> => {
	return fetch(`/${profile}/page.json`, {cache: 'no-cache'}).then(response => {
		const contentType = response.headers.get('content-type');
		if (!contentType || !contentType.includes('application/json')) {
			window.location.assign(REDIRECT_URL);
			return null;
		}
		return response.json();
	});
};
