import {type TypePageData} from './services';

// @see http://youmightnotneedjquery.com/#trigger_custom
export const triggerCustomEvent = (element: Element | Document, event_type: string, optional_data?: any): Event => {
	let event;
	if (window.CustomEvent && typeof window.CustomEvent === 'function') {
		event = new CustomEvent(event_type, optional_data ? {detail: optional_data} : undefined);
	} else {
		// IE fallback
		event = document.createEvent('CustomEvent');
		event.initCustomEvent(event_type, true, true, optional_data);
	}

	element.dispatchEvent(event);
	return event;
};

export const getProfileFromUrl = (): string => {
	const pathname = window.location.pathname;
	return pathname.split('/')[1];
};

export const setMetadataForPage = (page: TypePageData) => {
	setDocumentTitle(page.profile_name);
	setMetaDescription(page.profile_name);
	setOpenGraphTags(page);
};

export const setDocumentTitle = (profile: string) => {
	document.title = `${profile} | Sproutlink in bio`;
};

export const setMetaDescription = (profile: string) => {
	const el = document.querySelector("meta[name='description']");
	if (el) {
		el.setAttribute('content', `Check out Instagram posts from ${profile}'s link in bio`);
	} else {
		const description = document.createElement('meta');
		description.setAttribute('name', 'description');
		description.content = `Check out Instagram posts from ${profile}'s link in bio`;
		document.head?.appendChild(description);
	}
};

export const setOpenGraphTags = (page: TypePageData) => {
	const head = document.head;
	const docFragment = document.createDocumentFragment();

	// og:title
	const ogTitle = document.createElement('meta');
	ogTitle.setAttribute('property', 'og:title');
	ogTitle.content = `${page.profile_name} | Sproutlink in bio`;
	docFragment.appendChild(ogTitle);

	// og:image
	const ogImage = document.createElement('meta');
	ogImage.setAttribute('property', 'og:image');
	ogImage.content = page.hero_image_url || page.profile_image_url;
	docFragment.appendChild(ogImage);

	// og:type
	const ogType = document.createElement('meta');
	ogType.setAttribute('property', 'og:type');
	ogType.content = 'profile';
	docFragment.appendChild(ogType);

	// og:url
	const ogUrl = document.createElement('meta');
	ogUrl.setAttribute('property', 'og:url');
	ogUrl.content = `https://sprout.link/${page.profile_name}`;
	docFragment.appendChild(ogUrl);

	head?.appendChild(docFragment);
};

// @link https://stackoverflow.com/a/54947757
export const getHostname = (url: string): string | null | undefined => {
	try {
		return new URL(url).hostname;
	} catch (e: any) {
		// Use regex if `URL` is unsupported
		const hostnameMatch = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
		return hostnameMatch?.[1];
	}
};

const localeOptions = {year: 'numeric', month: 'long', day: 'numeric'} as const;

export const getStringDate = (epoch: number, locales: string | Array<string> = []): string => {
	const date = new Date(epoch * 1000);
	return `${date.toLocaleDateString(locales, localeOptions)}`;
};

export const getPostImageAriaLabel = (profileName: string, postDate: number): string => {
	return `Post from ${profileName} on ${getStringDate(postDate)}`;
};

export const REDIRECT_URL = 'https://sproutsocial.com/features/link-in-bio/';

// @see https://sentry.io/settings/sprout-social/projects/sproutlink/
export const SENTRY_DSN = 'https://563cf3fffcaf4aab89011557ac18aedd@o1064404.ingest.sentry.io/6071498';

export const MOBILE_BREAKPOINT = '992px';

export const CONTAINER_MAX_WIDTH = '860px';

/**
 * Show this many images at a time through infinite scroll.
 * Ideally is a multiple of 3 to nicely be divided by our 3 column layout.
 */
export const POSTS_COUNT_PER_SLICE = 30;

// Trigger loading more once we are this far away from the bottom.
export const LOAD_MORE_TRIGGER_POINT = '40vh';
