import styled from 'styled-components';
import {Box, Text} from '@sproutsocial/racine';
import {type TypePageData} from '../services';
import {MOBILE_BREAKPOINT, CONTAINER_MAX_WIDTH} from '../utils';

const Row = styled(Box)`
	display: flex;

	@media (min-width: ${MOBILE_BREAKPOINT}) {
		max-width: ${CONTAINER_MAX_WIDTH};
		margin: 0 auto;
	}
`;

// 9/16 is the aspect ratio for the hero image
const HeroImage = styled(Box)<{image?: string}>`
	height: 0;
	position: relative;
	width: 100%;
	padding-bottom: ${(9 / 16) * 100}%;
	background: no-repeat center center;
	background-size: cover;
	background-image: ${props => (props.image ? `url(${props.image})` : null)};
`;

const HeroText = styled(Text)`
	background-color: ${props => props.theme.colors.neutral[0]};
	${props => props.theme.typography[300]};
	font-weight: bold;
	text-align: center;
	padding: ${props => props.theme.space[450]};
	@media (max-width: ${MOBILE_BREAKPOINT}) {
		// design told us to use 9px
		padding-right 9px;
		padding-left: 9px;
	}
`;

type TypeProps = {
	page: TypePageData | null | undefined;
};

export default function PageIntro({page}: TypeProps) {
	return (
		<Row flexDirection='column'>
			{/*
				Until we have useful descriptive text for HeroImage,
				don't mark it with `role="img"` and instead keep it purely decorative.
			*/}
			{page?.hero_image_url && <HeroImage data-qa-component='hero-image' image={`/${page.hero_image_url}`} />}
			{page?.hero_text && <HeroText>{page.hero_text}</HeroText>}
			<Box bg='rgba(255, 255, 255, 0.5)' display='flex' alignItems='center' justifyContent='center' p={300}>
				<Text fontSize={200} textAlign='center'>
					Tap on an image to learn more
				</Text>
			</Box>
		</Row>
	);
}
