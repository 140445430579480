import {Fragment, useCallback} from 'react';
import {Box, Avatar, Text, Button} from '@sproutsocial/racine';
import {type TypePageData} from '../services';
import {pollinateSproutLink} from '../pollination';
type TypeProps = {
	page: TypePageData | null | undefined;
};

const Header = ({page}: TypeProps) => {
	const error = () => {
		throw new Error('Whoops!');
	};

	// test if onClicks get captured properly
	// one idea is https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
	const pollinateButton = useCallback(() => {
		if (!page) return;
		const {link_button_url, page_id} = page;

		pollinateSproutLink(
			{feature: 'button', action: `${page_id || 0}_button`, id: page_id},
			{destination_url: link_button_url}
		);
	}, [page]);

	return (
		<Box className='Header' as='header'>
			{page ? (
				<Fragment>
					<a href={`https://www.instagram.com/${page.profile_name}`} rel='noreferrer'>
						<Avatar src={`/${page.profile_image_url}`} name={page.profile_name} mr={300} />
					</a>
					<Text as='h1' fontSize={300} fontWeight='semibold'>
						{page.profile_name}
					</Text>
					<Button display='none' onClick={error}>
						Trigger Error
					</Button>
					{page.link_button_text && page.link_button_url && (
						<Button appearance='secondary' href={page.link_button_url} onClick={pollinateButton} ml='auto'>
							{page.link_button_text}
						</Button>
					)}
				</Fragment>
			) : (
				<Fragment>
					<Box className='Loader--circle' />
					<Box className='Loader--rectangle' />
				</Fragment>
			)}
		</Box>
	);
};

export default Header;
