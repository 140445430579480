import {SENTRY_DSN} from './utils';

type TypeCaptureErrorOptions = {
	tags?: {
		[key: string]: any;
	};
	extra?: {
		[key: string]: any;
	};
	unsetWindowListeners?: boolean;
};

export const captureError = (
	error: Error,
	{tags, extra, unsetWindowListeners = false}: TypeCaptureErrorOptions = {}
) => {
	if (process.env.NODE_ENV !== 'production') {
		return;
	}

	// eslint-disable-next-line no-console
	console.error(error);

	// Use Promise API rather than async/await so we don't have to include regenerator-runtime
	import('@sentry/browser').then(
		Sentry => {
			try {
				Sentry.init({
					dsn: SENTRY_DSN,
					environment: process.env.NODE_ENV,
				});

				// @see https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
				let capturedContent;
				if (tags || extra) {
					capturedContent = {tags, extra};
				}
				Sentry.captureException(error, capturedContent);
			} catch (e: any) {
				// eslint-disable-next-line no-console
				console.error('Failed to capture exception', error, e);
			}
		},

		/**
		 * When a dynamic import fails, even if you have a `try/catch` block surrounding it,
		 * it still calls the `window.onunhandledrejection` handler. So, we handle
		 * the rejection case within the Promise directly, rather than in a surrounding
		 * catch block.
		 */
		function failedToImportSentry(error) {
			// Something else failed, reset window handlers to prevent infinite loop
			// eslint-disable-next-line no-console
			console.error('Logging to Sentry failed', error);
			if (unsetWindowListeners) {
				window.onerror = null;
				window.onunhandledrejection = null;
			}
		}
	);
};

export const attachErrorListenerForSentry = () => {
	if (process.env.NODE_ENV !== 'production') {
		return;
	}

	window.onerror = (message: any, url: any, line: any, column: any, error?: Error | null) => {
		/**
		 * If no corresponding Error Object is available, `error` will be `null`.
		 * @see https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#window.onerror
		 */
		if (!error) {
			error = new Error(message);
		}

		return captureError(error, {unsetWindowListeners: true});
	};
	window.onunhandledrejection = (event: any) => captureError(event.reason, {unsetWindowListeners: true});
};
