import {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Box} from '@sproutsocial/racine';
import Post from './post';
import LoadMoreController from './load-more-controller';
import {type TypePostData, type TypePageData} from '../services';
import {MOBILE_BREAKPOINT, CONTAINER_MAX_WIDTH, POSTS_COUNT_PER_SLICE} from '../utils';

const Wrapper = styled.div`
	@media (min-width: ${MOBILE_BREAKPOINT}) {
		max-width: ${CONTAINER_MAX_WIDTH};
		margin: 0 auto;
	}
`;

const PostsGrid = styled(Box)`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 4px;
`;

type TypeProps = {
	page: TypePageData | null | undefined;
	posts: Array<TypePostData>;
};

const PostsList = ({page, posts}: TypeProps) => {
	// Set to false until we have our posts to calculate if we need to add infinite scrolling
	const [canLoadMore, setCanLoadMore] = useState(false);
	const [postsCurrentSlice, setPostsCurrentSlice] = useState(1);

	const loadMorePosts = useCallback(() => {
		// Use callback-style set vs `postsCurrentSlice + 1` so our `loadMorePosts` won't change on re-renders
		setPostsCurrentSlice(c => c + 1);
	}, []);

	useEffect(() => {
		const totalSlices = Math.ceil(posts.length / POSTS_COUNT_PER_SLICE);

		if (totalSlices > 1 && postsCurrentSlice > totalSlices) {
			// We've loaded everything, unmount infinite scroller
			setCanLoadMore(false);
		} else if (posts.length > POSTS_COUNT_PER_SLICE) {
			// We need to chunk out our posts, so enable infinite scroller
			setCanLoadMore(true);
		}
	}, [posts, postsCurrentSlice]);

	const profile_name = page?.profile_name || '';
	const sliceEnd = POSTS_COUNT_PER_SLICE * postsCurrentSlice;
	const postsToRender = posts.slice(0, sliceEnd);

	return (
		<Fragment>
			<Wrapper>
				<PostsGrid>
					{postsToRender.map(post => {
						return (
							<Post
								key={post.post_guid}
								page={page}
								link={post.link}
								mediaType={post.media_type}
								mediaURL={post.media_url}
								postDate={post.post_created_epoch}
								postGuid={post.post_guid}
								profileName={profile_name}
							/>
						);
					})}
				</PostsGrid>
			</Wrapper>
			{canLoadMore && <LoadMoreController loadMore={loadMorePosts} />}
		</Fragment>
	);
};

export default PostsList;
