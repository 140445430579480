import styled from 'styled-components';
import useInfiniteLoader from 'react-use-infinite-loader';

import {LOAD_MORE_TRIGGER_POINT} from '../utils';

const LoadMoreTrigger = styled.div`
	/* @see https://www.npmjs.com/package/react-use-infinite-loader */
	width: 1px;
	height: 1px;
	position: absolute;

	/* Lift this element up via negative margin */
	margin-top: -${LOAD_MORE_TRIGGER_POINT};
`;

type TypeProps = {
	loadMore: () => void;
};

/**
 * Purposefully put `useInfiniteLoader` in a lower component that can be completely
 * unmounted to remove the intersection observer when all posts have loaded. Since
 * we are controlling the loading mechanism through mounts, we always set `canLoadMore`
 * to true in this component, and use state higher up in the tree to handle mounts
 * and unmounts.
 */
export default function LoadMoreController({loadMore}: TypeProps) {
	const {loaderRef} = useInfiniteLoader<HTMLDivElement>({
		loadMore,
		canLoadMore: true,
	});

	return <LoadMoreTrigger ref={loaderRef} />;
}
