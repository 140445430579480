export type TypePollen = {
	feature: string;
	action: string;
	trigger?: string;
	type?: string;
	properties?: any;
	id?: number;
};

const TypesByTriggers: Record<string, any> = {};
const EventTriggers = ['load', 'app', 'view', 'modal'];
const ActionTriggers = ['click', 'scroll', 'key', 'focus', 'blur', 'hover', 'mousedown', 'mouseenter', 'submit'];

ActionTriggers.forEach(trigger => {
	TypesByTriggers[trigger] = 'action';
});
EventTriggers.forEach(trigger => {
	TypesByTriggers[trigger] = 'event';
});

const typeOfTrigger = (name: string): string | null | undefined => TypesByTriggers[name];

const NullPollen: TypePollen = {feature: '', action: '', trigger: '', type: ''};

const getPollen = (spec: any): TypePollen | null | undefined => {
	const pollen = spec.__POLLEN__;

	return pollen === NullPollen ? null : pollen;
};

// @todo include `id` in the return value
const newPollenFromPair = (path: string[], action: string): TypePollen => {
	const count = path.length;
	let index = count - 2;

	do {
		let name = path[index];
		let type = typeOfTrigger(name);

		if (type) {
			const feature = path.slice(0, index).join('.');
			const trigger = path.slice(index).join('.');

			return {feature, action, trigger, type};
		}
	} while (++index < count);

	return {feature: path.join('.'), action, trigger: 'click', type: 'action'};
};

const newPollenFromString = (spec: string): TypePollen | null | undefined => {
	const pollenStringSpecMatcher = /([\w\.]+)\s*:\s*([\w\.]+)/;
	const match = spec.match(pollenStringSpecMatcher);

	if (!match) return undefined;

	const path = match[1].split('.');
	const action = match[2];

	return newPollenFromPair(path, action);
};

const newPollenFromSpec = (spec: any): TypePollen | null | undefined => {
	const keys = Object.keys(spec);

	if (keys.length === 1) {
		const key = keys[0];
		const action = spec[key];
		const path = key.split('.');

		return typeof action === 'string' ? newPollenFromPair(path, action) : undefined;
	}

	const {feature, action} = spec;

	if (feature === '' || action === '' || typeof action !== 'string') return undefined;

	const {trigger = 'click', type = 'action', properties = {}, id} = spec;

	return {feature, action, trigger, type, properties, id};
};

const newPollen = (spec: string | any): TypePollen | null | undefined => {
	return typeof spec === 'string' ? newPollenFromString(spec) : newPollenFromSpec(spec);
};

const mergedPollen = (pollen: TypePollen, pProperties: any = {}, additionalAttributes: any = {}): TypePollen => {
	const {feature, action, trigger, type, properties, id} = pollen;
	const merged = Object.assign({}, properties, pProperties);

	return {feature, action, trigger, type, id, properties: merged, ...additionalAttributes};
};

export {getPollen, newPollenFromPair, newPollenFromString, newPollenFromSpec, newPollen, mergedPollen, NullPollen};
