import 'intersection-observer';

import App from './components/app';
import {attachErrorListenerForSentry} from './captureError';
import {ThemeProvider, sproutLightTheme} from '@sproutsocial/racine';

import {render} from 'react-dom';

import './styles.css';

attachErrorListenerForSentry();

render(
	<ThemeProvider theme={sproutLightTheme}>
		<App />
	</ThemeProvider>,
	document.getElementById('app')
);
