import type {TypePollen} from './pollen';

export const POLLINATOR_DOMAIN = 'https://sproutlink.pollinator.me/sproutlink';

export const pollinationRequest = (pollen: TypePollen): Promise<any> => {
	const {feature, action, trigger, properties, id} = pollen;

	/**
	 * All values need to be strings, otherwise typescript (correctly?) complains
	 * @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams#parameters
	 */
	const pollinatorObject = {
		p_feat: feature?.toString() || '',
		p_action: action?.toString() || '',
		p_trigger: trigger?.toString() || '',
		p_properties: JSON.stringify(properties) || '',
		p_url: id?.toString() || '0',
		p_timestamp: Math.floor(Date.now() / 1000).toString(),
	} as const;

	// @note Should we polyfill `URLSearchParams` for IE support?
	const query_params = new URLSearchParams(pollinatorObject).toString();

	const img = new Image();

	const pollinate_promise = new Promise(resolve => {
		/**
		 * The image we are loading probably will _always_ fail to load
		 * because we are trying to load a zero-byte image (polinator returns
		 * a GIF of Content-Length 0), hence resolving on both `load` and `error`.
		 * The image not loading doesn't mean we didn't get a 200 response.
		 * @see https://github.com/sproutsocial/web-app-core/blob/6620ada6c8/script/core/pollinator/pollinator.js#L259-L260
		 * @see https://github.com/sproutsocial/web-app-core/blob/08129650a2/script/core/pollinator/index.js#L369-L372
		 */
		img.onload = resolve;
		img.onerror = resolve;
		img.src = `${POLLINATOR_DOMAIN}?${query_params}`;
	});

	return pollinate_promise;
};
