import styled from 'styled-components';
import {useState, memo} from 'react';
import {Box} from '@sproutsocial/racine';
import IconStyles from './racine-icon-styles';

import VideoCameraSvg from '@sproutsocial/racine/icons/video-camera.svg';
import ImagesSvg from '@sproutsocial/racine/icons/images.svg';

import {type EnumMediaType, type TypePageData} from '../services';

import {getPostImageAriaLabel} from '../utils';
import {pollinateSproutLink} from '../pollination';

const MEDIA_TYPES = {
	video: 'video',
	carousel: 'carousel',
} as const;

const MediaIconContainer = styled(Box)`
	position: absolute;
	top: ${props => props.theme.space[300]};
	right: ${props => props.theme.space[300]};
	padding: ${props => props.theme.space[200]};
	color: ${props => props.theme.colors.neutral[0]};
	font-size: 0;
`;

const PostLink = styled.a`
	display: block;
	position: relative;
	overflow: hidden;

	// Creates 1:1 square box
	padding-bottom: 100%;

	&:hover,
	&:focus {
		opacity: 0.7;
	}
`;

const PostImage = styled.img`
	// Stretch image within 1:1 container
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	max-width: 100%;

	// Crop image to conform to parent 1:1 aspect ratio
	object-fit: cover;
`;

type TypeProps = {
	link: string;
	mediaURL: string;
	mediaType: EnumMediaType;
	page: TypePageData | null | undefined;
	postDate: number;
	profileName: string;
	postGuid: string;
};

const Post = ({link, mediaURL, mediaType, page, postDate, profileName, postGuid}: TypeProps) => {
	const [image_exists, setImageExists] = useState(true);

	const pollinatePostClick = () => {
		pollinateSproutLink({feature: 'instagram_post', action: postGuid, id: page?.page_id}, {destination_url: link});
	};

	const ariaLabel = getPostImageAriaLabel(profileName, postDate);

	if (!image_exists) {
		return null;
	}

	return (
		<PostLink href={link} target='_blank' rel='noreferrer' onClick={pollinatePostClick}>
			<PostImage
				src={mediaURL}
				alt={ariaLabel}
				onError={() => {
					setImageExists(false);
				}}
			/>
			{mediaType === MEDIA_TYPES.video && (
				<MediaIconContainer>
					<IconStyles aria-label='Video Media Type' data-qa-icon='video-camera' fixedWidth={false} iconSize='default'>
						<VideoCameraSvg className='Icon-svg' focusable={false} />
					</IconStyles>
				</MediaIconContainer>
			)}
			{mediaType === MEDIA_TYPES.carousel && (
				<MediaIconContainer className='postContainer-mediaType'>
					<IconStyles aria-label='Carousel Media Type' data-qa-icon='images' fixedWidth={false} iconSize='default'>
						<ImagesSvg className='Icon-svg' focusable={false} />
					</IconStyles>
				</MediaIconContainer>
			)}
		</PostLink>
	);
};

export default memo<TypeProps>(Post);
