// @ts-expect-error We can't create a declaration file for this for some reason
import IconStyles from '@sproutsocial/racine/lib/Icon/styles.js';

import {type StyledComponent} from 'styled-components';
import {type TypeSproutTheme} from '@sproutsocial/racine';

const Container = IconStyles as StyledComponent<'span', TypeSproutTheme>;

/**
 * This function is a "pass through" for `racine/lib/Icon/styles.js`, which needs
 * to wrap the icons so that it gets niceties like inheriting the current color,
 * height adjustments, and others.
 *
 * We are doing this here because trying to make a '.d.ts' file for `racine/lib/Icon/styles.js`
 * turned out to not be possible. That is, whenever I'd also import `type StyledComponent`
 * and try to annotate the component as such, the file would fail to be parsed at
 * all and TS would complain.
 *
 * So the workaround is it expect an error on the import, then type cast to `StyledComponent`
 * (even though it technically is incorrect, e.g., the component takes in props than
 * what `StyledComponent` includes, like all our styled-system props), and spread
 * all the props into that.
 *
 * While not perfect, it is better than just rendering an `any` component.
 */
export default function IconStylesPassThrough(props: React.ComponentPropsWithRef<typeof Container>) {
	return <Container {...props} />;
}
