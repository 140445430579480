import {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';

import {getHostname, getProfileFromUrl, setMetadataForPage, REDIRECT_URL} from '../utils';
import {getPageData, getPostsData, type TypePageData, type TypePostData} from '../services';
import {pollinateSproutLink} from '../pollination';
import {captureError} from '../captureError';

import {Box} from '@sproutsocial/racine';
import {ErrorBanner} from './errorBanner';
import Header from './header';
import PostsList from './postsList';
import PageIntro from './page-intro';

const PostsContainer = styled(Box)`
	position: relative;
	width: 100vw;
	min-height: calc(100vh - 65px);
	padding-top: 65px;
	background-color: ${props => props.theme.colors.neutral[100]};
	color: ${props => props.theme.colors.neutral[800]};
`;

const App = () => {
	const profile = getProfileFromUrl();
	const [hasError, setHasError] = useState(false);
	const [posts, setPosts] = useState<TypePostData[]>([]);
	const [page, setPage] = useState<TypePageData | null | undefined>(null);

	useEffect(() => {
		if (!page) {
			return;
		}
		const {page_id} = page;
		const referrer = getHostname(document.referrer);

		pollinateSproutLink(
			{
				feature: 'page',
				action: page_id?.toString() || '0',
				trigger: 'load',
				id: page_id,
			},
			referrer ? {referrer} : undefined
		);
	}, [page]);

	useEffect(() => {
		if (!profile) {
			window.location.assign(REDIRECT_URL);
			return;
		}

		getPageData(profile)
			.then(page => {
				if (page) {
					setPage(page);
					setMetadataForPage(page);
				}
			})
			.catch(error => {
				captureError(error, {tags: {request: 'page-fetch'}});
			});

		getPostsData(profile)
			.then(posts => setPosts(posts))
			.catch(error => {
				// Only show error banner if posts fail
				setHasError(true);
				captureError(error, {tags: {request: 'posts-fetch'}});
			});
	}, [profile]);

	return (
		<Fragment>
			<Header page={page} />
			<PostsContainer as='main'>
				{hasError && <ErrorBanner />}
				<PageIntro page={page} />
				<PostsList page={page} posts={posts} />
			</PostsContainer>
		</Fragment>
	);
};

export default App;
