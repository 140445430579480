import {pollinationRequest} from './services';
import {getPollen, newPollen, mergedPollen} from './pollen';
import normalize from './normalize';

import type {TypePollen} from './pollen';

//
// The pollinate function can be called in a variety of ways:
//
// You can pass it a hierarchical pollen constant:
// pollinate(pollen.drawer.links_manager.my_links_tab);
//
// You can pass it a partially specified pollen spec:
// pollinate({feature: 'drawer.links_manager.my_links_tab', action: 'view_my_links'});
//
// You can pass it a shortcut pollen spec:
// pollinate({'drawer.links_manager.my_links_tab': 'view_my_links'});
//
// You can pass it a pollen string-based spec
// pollinate('drawer.links_manager.my_links_tab : view_my_links');
//
// Also, all the above ways can include an optional pProperties argument and additionalAttributes to set more attributes if needed
//
const pollinate = (spec: string | any, extraProperties: any = {}) => {
	//if (process.env.NODE_ENV !== 'production') return;

	let basePollen = getPollen(spec);

	if (basePollen === null) {
		// Add Sentry alert for attempt to get pollen from improper node of the hierarchical pollen tree!!!!!
		return;
	}

	if (basePollen === undefined) {
		basePollen = newPollen(spec);

		if (!basePollen) {
			// Add Sentry alert for malformed pollen spec!!!!!
			return; // Add Sentry entry for malformed pollen!!!!!
		}
	}

	const fullPollen = mergedPollen(basePollen, extraProperties);

	pollinationRequest(fullPollen);
};

const bloomPollen = (spec: any) => {
	normalize(spec, []);
};

export {pollinate, bloomPollen};

export type {TypePollen};
