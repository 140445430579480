import {Banner, Box} from '@sproutsocial/racine';
import TriangleSvg from '@sproutsocial/racine/icons/triangle.svg';

export const ErrorBanner = () => (
	<Box py={300} px={300} maxWidth={800} mx='auto'>
		{/*
			The `error` banner includes a reference to the triangle icon.
			Since we are no longer including all icons, this ends up not
			rendering. So we need to include the icon somewhere in the
			document and give it the same ID that Racine is going to reference.
			Additionally, we need to make the source icon not visible. If
			we were to set `display: none` on the SVG directly, then
			the Banner `<use>`ing that icon would also show a `display: none`'d
			version of the triangle, hence this wrapper div.

			This seems a little hacky, but should work.
		*/}
		<div style={{display: 'none'}}>
			<TriangleSvg id='ssiconsvg-triangle' />
		</div>
		<Banner
			type='error'
			text={
				<Box>
					We're sorry, but something went wrong. We're working on fixing the problem. Please try again.{' '}
					<a href='#' onClick={() => window.location.reload()}>
						Refresh page.
					</a>
				</Box>
			}
		/>
	</Box>
);
